import classes from "./WrapperVideo.module.scss";
import wedding from "../Videos/wedding.mov";

const WrapperVideo = () => {
  const randNum = Math.floor(Math.random() * 5) + 1;
  return (
    <div className={classes["wrapper-video"]}>
      <div className={classes.mobile}>
        <video autoPlay muted playsInline loop id={classes.weddingVideoMobile}>
          <source
            src={require(`../Videos/wedding-mobile-${randNum}.mov`)}
            type="video/mp4"
          />
          Your browser does not support HTML5 video.
        </video>
      </div>
      <div className={classes.desktop}>
        <video autoPlay muted loop id={classes.weddingVideoDesktop}>
          <source src={wedding} type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>
      </div>
    </div>
  );
};

export default WrapperVideo;
