import classes from "./AddToCalendar.module.scss";
import { atcb_action } from "add-to-calendar-button";
import "add-to-calendar-button/assets/css/atcb.css";
import { Button } from "react-bootstrap";

const AddToCalendar = () => {
  const addToCalendarHandler = () => {
    atcb_action({
      name: "Nuntă Bela & Moni",
      startDate: "2023-08-26",
      endDate: "2023-08-27",
      options: ["Apple", "Google"],
      timeZone: "Europe/Bucharest",
    });
  };
  return (
    <Button
      className={classes["add-to-calendar"]}
      variant="primary"
      onClick={addToCalendarHandler}
    >
      <i className="fas fa-calendar-plus"></i>&nbsp; Adaugă la Calendar
    </Button>
  );
};

export default AddToCalendar;
