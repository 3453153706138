import classes from "./Countdown.module.scss";
import "./Countdown.css";
import { useState } from "react";

const Countdown = (props) => {
  const [daysPassed, setDaysPassed] = useState(0);
  const counter = (providedDate) => {
    const second = 1000,
      minute = second * 60,
      hour = minute * 60,
      day = hour * 24;

    //I'm adding this section so I don't have to keep updating this pen every year :-)
    //remove this if you don't need it
    let today = new Date(),
      dd = String(today.getDate()).padStart(2, "0"),
      mm = String(today.getMonth() + 1).padStart(2, "0"),
      yyyy = today.getFullYear(),
      // nextYear = yyyy + 1,
      dayMonth =
        providedDate.split("/")[0] + "/" + providedDate.split("/")[1] + "/",
      year = providedDate.split("/")[2],
      theday = dayMonth + year;

    today = mm + "/" + dd + "/" + yyyy;
    // if (today > theday) {
    //   theday = dayMonth + nextYear;
    // }
    //end

    const countDown = new Date(theday).getTime(),
      x = setInterval(function () {
        const now = new Date().getTime(),
          distance = countDown - now;

        if (distance / day < 0) {
          setDaysPassed(Math.abs(Math.trunc(distance / day)));
        }

        document.getElementById("days").innerText = Math.floor(distance / day);
        document.getElementById("hours").innerText = Math.floor(
          (distance % day) / hour
        );
        document.getElementById("minutes").innerText = Math.floor(
          (distance % hour) / minute
        );
        document.getElementById("seconds").innerText = Math.floor(
          (distance % minute) / second
        );

        if (distance < 0) {
          document.getElementById("countdown").style.display = "none";
          clearInterval(x);
        }
        if (distance > -86400000 && distance < 0) {
          document.getElementById("today").style.display = "block";
          document.getElementById("passed").style.display = "none";
        }
        // console.log(distance);
        if (distance < -86400000) {
          document.getElementById("passed").style.display = "block";
          document.getElementById("today").style.display = "none";
        }
      }, 0);
  };

  counter(props.date);

  return (
    <div className={classes.container}>
      <div id="countdown">
        <h2 className={classes.wedding}>Au mai rămas:</h2>
        <ul>
          <li>
            <span id="days"></span>Zile
          </li>
          <li>
            <span id="hours"></span>Ore
          </li>
          <li>
            <span id="minutes"></span>Minute
          </li>
          <li>
            <span id="seconds"></span>Secunde
          </li>
        </ul>
      </div>
      <div id="today">
        <h2 className={classes.wedding}>
          ...adică azi!!!{" "}
          <span>
            <i className="fas fa-glass-cheers"></i>
          </span>
        </h2>
      </div>
      <div id="passed">
        <h2 className={classes.wedding}>
          Nunta noastră a avut loc acum {daysPassed} zile!{" "}
          <span>
            <i className="fas fa-glass-cheers"></i>
          </span>
        </h2>
      </div>
    </div>
  );
};

export default Countdown;
