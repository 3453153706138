import Countdown from "./Countdown";
import classes from "./Content.module.scss";
import "bootstrap/dist/css/bootstrap.css";
import { Container, Row, Col } from "react-bootstrap";
import AddToCalendar from "./AddToCalendar";

const Content = () => {
  return (
    <Container className={classes["content-wrapper"]}>
      <Row>
        <Col>
          <h3 className={classes["wedding-desktop"]}>Salvați data!</h3>
          <h4
            className={classes["wedding-desktop"]}
            style={{ fontWeight: 300 }}
          >
            Nunta noastră va avea loc în
          </h4>
        </Col>
      </Row>
      <Row>
        <h1>26 august 2023</h1>
      </Row>
      <Row>
        <Col className="d-none d-md-block d-lg-block">
          <Countdown date="8/26/2023" />
        </Col>
      </Row>
      <Row>
        <Col
          className={classes.calendar}
          xs={{ span: 12, order: 2 }}
          sm={{ span: 6, order: 1 }}
          lg={{ span: 6, order: 1 }}
        >
          <AddToCalendar />
        </Col>
        <Col
          xs={{ span: 12, order: 1 }}
          sm={{ span: 6, order: 2 }}
          lg={{ span: 6, order: 2 }}
        >
          <h2 className={`${classes.wedding} ${classes.signature}`}>
            Bela și Moni
          </h2>
        </Col>
      </Row>
    </Container>
  );
};

export default Content;
