import WrapperVideo from "./Components/WrapperVideo";
import Content from "./Components/Content";
import classes from "./App.module.scss";

import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <>
      <WrapperVideo />
      <div className={classes.app}>
        <Content />
      </div>
    </>
  );
}

export default App;
